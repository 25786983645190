import React, { useState } from 'react';
import axios from 'axios';

const Upload = () => {
  // State to store form data
  const [formData, setFormData] = useState({
    title: '',
    startTime: '',
    endTime: '',
    venue: '',
    description: '',
    speaker: '',
    date: ""
  });

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

  const [ loading, setLoading ] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
        console.log(formData)
      // Make POST request with form data
      const res = await axios.post("https://wf-chrie.onrender.com/api/session", formData, {
        headers : {
            Authorization : "Bearer eyJhbGciOiJIUzI1NiJ9.NjU5YjI0ZjlmOWIwODBjYWRhYjJmYjQ2.yoPQywIdZum09A-wJZVErBlGgvCzxy4ajIa087BDUa0"
        }
      });

      // Handle success (e.g., show a success message or redirect)
      console.log("Post request successful:", res.data);
      setLoading(false);
      setFormData({
        title: '',
        startTime: '',
        endTime: '',
        venue: '',
        description: '',
        speaker: '',
        date: ""
      });
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error("Error making POST request:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='py-3 px-5'>
        <label htmlFor="title">Title</label>
        <input
            className='bg-gray-100 rounded w-full py-3 px-2'
          type="text"
          name="title"
          required
          value={formData.title}
          onChange={handleInputChange}
        />
      </div>
      <div className='py-3 px-5'>
        <label htmlFor="startTime">Start Time</label>
        <input
            className='bg-gray-100 rounded w-full py-3 px-2'
          type="time"
          name="startTime"
          required
          value={formData.startTime}
          onChange={handleInputChange}
        />
      </div>
      <div className='py-3 px-5'>
        <label htmlFor="endTime">End Time</label>
        <input
            className='bg-gray-100 rounded w-full py-3 px-2'
          type="time"
          name="endTime"
          required
          value={formData.endTime}
          onChange={handleInputChange}
        />
      </div>
      <div className='py-3 px-5'>
        <label htmlFor="endTime">Date</label>
        <input
            className='bg-gray-100 rounded w-full py-3 px-2'
          type="date"
          name="date"
          required
          value={formData.date}
          onChange={handleInputChange}
        />
      </div>
      <div className='py-3 px-5'>
        <label htmlFor="venue">Venue</label>
        <input
            className='bg-gray-100 rounded w-full py-3 px-2'
          type="text"
          name="venue"
          value={formData.venue}
          onChange={handleInputChange}
        />
      </div>
      <div className='py-3 px-5'>
        <label htmlFor="venue">Color</label>
        <select
            className='bg-gray-100 rounded w-full py-3 px-2'
          type="text"
          name="color"
          value={formData.color}
          onChange={handleInputChange}
        >
                      <option value="red">Red</option>
          <option value="blue">Blue</option>
          <option value="green">Green</option>
          <option value="indigo">Indigo</option>
          <option value="gray">gray</option>
          <option value="purple">purple</option>
        </select>
      </div>
      <div className='py-3 px-5'>
        <label htmlFor="description">Description</label>
        <textarea
            className='bg-gray-100 rounded w-full py-3 px-2'
          type="text"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        ></textarea>
      </div>
      <div className='py-3 px-5'>
        <label htmlFor="speaker">Speaker</label>
        <input
            className='bg-gray-100 rounded w-full py-3 px-2'
          type="text"
          name="speaker"
          value={formData.speaker}
          onChange={handleInputChange}
        />
      </div>
      <button disabled={loading} className='bg-blue-500 px-8 py-2 mx-5 rounded' type="submit">Submit</button>
    </form>
  );
};

export default Upload;
