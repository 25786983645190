import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Contact from './components/Contact';
import Mining from './components/Pages/Mining';
import CookiePolicy from './components/Pages/CookiePolicy';
import Privacy from './components/Pages/Privacy';
import Terms from './components/Pages/Terms';
import Leverage from './components/Pages/Leverage';
import Responsible from './components/Pages/Responsible';
import General from './components/Pages/General';
import About from './components/Pages/About';
import Stock from './components/Pages/Stock';
import CryptoTrading from './components/Pages/CryptoTrading';
import Upload from './components/Upload';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path : "contact-us",
    element : <Contact/>
  },
  {
    path : "crypto-mining",
    element : <Mining/>
  },
  {
    path : "cookie",
    element : <CookiePolicy/>
  },
  {
    path : "privacy",
    element : <Privacy/>
  },
  {
    path : "terms",
    element : <Terms/>
  },
  {
    path : "leverage",
    element : <Leverage/>
  },
  {
    path : "stock-trading",
    element : <Stock/>
  },
  {
    path : "crypto-trading",
    element : <CryptoTrading/>
  },
  {
    path : "responsible-trading",
    element : <Responsible/>
  },
  {
    path : "general",
    element : <General/>
  },
  {
    path : "about-us",
    element : <About/>
  },
  {
    path : "upload",
    element : <Upload/>
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);