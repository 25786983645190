import { Disclosure } from '@headlessui/react'
import React from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Carousel } from 'react-responsive-carousel'
import {Fade} from 'react-reveal'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from 'react-router-dom'

const Section = () => {
  return (
    <div>
        <Flags/>
        <Features/>
        <Copy/>
        <Trust/>
        <Mine/>
        <Smart/>
        <Explore/>
        <FAQ/>
    </div>
  )
}

const Flags = () => {
    return(
        <div className='p-10 bg-sec text-white space-y-10'>
            <div className="grid grid-cols-4 gap-4">
                <Fade left>
                <img src="/smart/us.svg" alt="" srcset="" className='w-full h-full rounded-xl'/>
                </Fade>
                <Fade left>
                <img src="/smart/au.svg" alt="" srcset="" className='w-full h-full rounded-xl'/>
                </Fade>
                <Fade left>
                <img src="/smart/eu.svg" alt="" srcset="" className='w-full h-full rounded-xl'/>
                </Fade>
                <Fade left>
                <img src="/smart/ca.svg" alt="" srcset="" className='w-full h-full rounded-xl'/>
                </Fade> 
           </div>

            <div className='text-center'>
                <p className='text-3xl font-semibold'>Forex, Crypto and Beyond</p>
                <p>Discover hundreds of assets across multiple categories</p>
            </div>

            <div className='grid grid-cols-4 gap-4'>
                <Fade left>
                <img src="/smart/btc.svg" alt="btc" className='w-full rounded-xl' />
                </Fade>
                <Fade left>
                <img src="/smart/eth.svg" alt="btc" className='w-full rounded-xl' />
                </Fade>
                <Fade left>
                <img src="/smart/bnb.svg" alt="btc" className='w-full rounded-xl' />
                </Fade>
                <Fade left>
                <img src="/smart/shiba.svg" alt="btc" className='w-full rounded-xl' />
                </Fade>
            </div>
        </div>
    )
}

const features = [
    {
        title : "State of the Art Mining",
        img : "/smart/state.png",
        text : "Productivity is a top notch quality for any investment. So, for every trade action or blockchain mining algorithm that we offer, we’re providing some of the highest performing systems and softwares that exist        "
    },
    {
        title : "Daily Mining Outputs",
        img : "/smart/mine.png",
        text : "Returns made from either trading or mining transactions will be added to your account daily and automatically."
    },
    {
        title : "Diverse Mining Portfolio        ",
        img : "/smart/diverse.png",
        text : "Choose from a wide range of target assets for trading and over 10 major mining algorithms that cut across 4 mineable cryptocurrencies"
    },
    {
        title : "Intuitive Dashboard",
        img : "/smart/int.png",
        text : "All the financial instruments data and charts that you need, when you need them        "
    },
    {
        title : "Hardware is already running",
        img : "/smart/hardware.png",
        text : "Don’t wrestle with rig assembly and hot, noisy miners at home. We have the fastest bitcoin mining hardware running for you already        "
    },
    {
        title : "Secure and Private",
        img : "/smart/secure.png",
        text : "We value the privacy of our users, so we strive to keep collected user data to a minimum level."
    }
]

const Features = () => (
        <div className='grid bg-white text-black dark:bg-bg dark:text-white sm:grid-cols-3 gap-4 p-16 text-center'>
            {
                features.map((item) => (
                    <Fade bottom>
                        <div className='flex flex-col justify-center items-center p-5'>
                    <img src={item.img} alt="mine" className='h-26 w-26' />
        
                    <div>
                        <p className='text-2xl'>{item.title}</p>
                        <p>{item.text}</p>
                    </div>
                </div>
                    </Fade>
                ))
            }
        </div>
)

const Copy = () => (
    <div className='bg-secondary text-white grid sm:grid-cols-2 gap-4 px-5 py-16 sm:p-16'>
        <div>
            <video autoPlay playsInline muted loop className='w-full'>
                <source src='/smart/copy.mp4' type='video/mp4' />
            </video>        
        </div>
        <div className='flex justify-center flex-col gap-6 p-4'>
            <Fade bottom>
            <p className='text-3xl font-semibold'>Copy top investors</p>
            <p className="text-xl sm:text-3xl font-light">
            With Space X Avenue innovative CopyTrader™, you can automatically copy the moves of other investors.
            Find investors you believe in and replicate their actions in real time.
            </p>

            <Link to="https://Space X Avenue.com/accounts/dashboard">
                <button className='px-10 py-3 border border-blue-500 w-auto rounded-xl'>Start Copying</button>
            </Link>
            </Fade>
        </div>
    </div>
)

const trust = [
    {
        img : "/smart/social.svg",
        title : "Social",
        text : "More than 25 millions users globally"
    },
    {
        img : "/smart/reliable.svg",
        title : "Reliable",
        text : "Regulated by bodies around the world        "
    },
    {
        img : "/smart/secured.svg",
        title : "Secured",
        text : "Utilising top-tier security practices        "
    },
    {
        img : "/smart/global.svg",
        title : "Global",
        text : "Providing services around the world        "
    },
]

const Trust =() => (
    <div className='bg-gray-50 text-black dark:bg-black dark:text-white text-center px-5 py-16 sm:p-16 space-y-10'>
        <div>
            <p className='text-3xl'>Tusted Worldwide</p>
            <p className='text-black dark:text-text'>Discover why millions of investors from over 100 countries joined Space X Avenue</p>
        </div>

        <div className='grid sm:grid-cols-4 gap-8 '>
            {
                trust.map(item => (
                    <Fade bottom>
                        <div className='border dark:border-white rounded-xl p-5 sm:space-y-8'>
                            <img src={item.img} alt="omo" className='w-full' />
            
                            <div className='space-y-3'>
                                <p className="text-3xl">{item.title}</p>
                                <p className="dark:text-text">{item.text}</p>
                            </div>
                        </div>
                    </Fade>
                ))
            }
        </div>
    </div>
)


const Smart =() => (
    <div className='bg-gray-100 dark:bg-secondary text-black grid sm:grid-cols-2 gap-4 px-5 py-16 sm:p-16'>
        <div class="col-span-2">
            <p className='text-3xl text-center text-white'>CERTIFICATIONS</p>
        </div>
        <div>
            <img src="/doc.jpg" alt="" />      
        </div>
        <div className='flex justify-center flex-col gap-6 p-4'>
            <img src="/doc2.jpg" alt="" />
        </div>
    </div>
)

const Mine = () => (
    <div className='text-black bg-gray-50 dark:bg-black px-5 py-16 sm:p-16 space-y-10 dark:text-white'>
        <Fade bottom>
        <p className='text-3xl text-center'>MINE TOP CRYPTO CURRENCIES</p>
        <p className="dark:text-text">
            Unlike paper money, Bitcoin and other cryptocurrencies are produced mathematically and held digitally. The people who voluntarily offer their computing power to secure these cryptocurrency networks are called miners. Cryptocurrencies don’t have a central government or other so-called ‘middlemen’ that decide about the future of the system.
            <br /><br />
            As miners, we are processing and verifying the transactions of the cryptocurrency ecosystems and keeping their public transaction history (blockchains) maintained and secure. For this, the mining community is rewarded with the networks’ transaction fees and newly created coins.
        </p>

        <div className='grid grid-cols-2 sm:grid-cols-4 gap-10'>
            <div className='p-5 flex flex-col justify-center shadow-lg dark:bg-bg gap-5 items-center'>
                <img src="/smart/btc.svg" alt="btc" className='h-16 w-16' />
                <p>Bitcoin</p>
                <p>25572</p>
            </div>
            <div className='p-5 flex flex-col justify-center shadow-lg dark:bg-bg gap-5 items-center'>
                <img src="/smart/eth.svg" alt="btc" className='h-16 w-16' />
                <p>Ethereum</p>
                <p>25572</p>
            </div>
            <div className='p-5 flex flex-col justify-center shadow-lg dark:bg-bg gap-5 items-center'>
                <img src="/smart/bnb.svg" alt="btc" className='h-16 w-16' />
                <p>Binance Coin</p>
                <p>25572</p>
            </div>
            <div className='p-5 flex flex-col justify-center shadow-lg dark:bg-bg gap-5 items-center'>
                <img src="/smart/cosmos.svg" alt="btc" className='h-16 w-16' />
                <p>Cosmos</p>
                <p>6.767</p>
            </div>
        </div>
        </Fade>
    </div>
)

const Explore = () => (
    <div className='dark:bg-bg dark:text-white text-center px-5 py-16 sm:p-16 space-y-5'>
        <Fade bottom>
        <p className='text-4xl font-semibold'>Explore Space X Avenue</p>
        <p className='dark:text-text'>The evolution of one of the world’s largest cryptocurrency mining facilities</p>
        <p className='dark:text-text'>Space X Avenue is one of the largest cryptocurrency mining facilities in the world. First built to exclusively mine Ethereum, the facility is being continuously upgraded for mining state-of-the-art Blockchain technology. Space X Avenue’s computational performance is achieved with specifically designed mining rigs that efficiently mine hashing algorithms for various cryptocurrencies such as Zcash, Dash, Monero and others. The Space X Avenue facility is powered by geothermal energy, and resides in the capital of Iceland.</p>
    
        </Fade>
        <div>
            <Carousel>
                <div>
                    <div className='grid sm:grid-cols-2 dark:bg-black'>
                        <div className=''>
                            <img src="/smart/car1.webp" alt="" className='h-80' />
                        </div>
                        <div className='text-center px-10 space-y-4 flex flex-col justify-center items-center'>
                            <p className='text-5xl font-bold text-gray-400'>01</p>
                            <p className='text-4xl font-semibold dark:text-white'>Energy (Iceland + Geothermal Energy)</p>
                            <p className='dark:text-text font-light text-xl'>The results of cryptocurrency mining highly depend on infrastructure efficiency and electricity rates, which make renewable energy sources - such as geothermal and hydropower - the go-to solution for a cost-effective and clean mining operation. Today, over 80% of Iceland’s energy is provided by renewable sources.</p>
                        </div>
                    </div>                    
                </div>
                <div>
                    <div className='grid sm:grid-cols-2 dark:bg-black'>
                        <div className=''>
                            <img src="/smart/mine.webp" alt="" className='h-80' />
                        </div>
                        <div className='text-center px-10 space-y-4 flex flex-col justify-center items-center'>
                            <p className='text-5xl font-bold text-gray-400'>02</p>
                            <p className='text-4xl font-semibold dark:text-white'>Mining Hardware</p>
                            <p className='dark:text-text font-light text-xl'>Hive is our software interface which enables the efficient setup, configuration and management of all our mining facilities. Keeping track of all devices is key for the success of any mining operation. Besides the total hashrate, power consumption and facility temperatures there are a myriad of details and settings like firmware, mining pools and safety protections that need to be checked and managed.</p>
                        </div>
                    </div>                    
                </div>
                <div>
                    <div className='grid sm:grid-cols-2 dark:bg-black'>
                        <div className=''>
                            <img src="/smart/minable.webp" alt="" className='h-80' />
                        </div>
                        <div className='text-center px-10 space-y-4 flex flex-col justify-center items-center'>
                            <p className='text-5xl font-bold text-gray-400'>02</p>
                            <p className='text-4xl font-semibold dark:text-white'>Minable Cryptocurrencies (Algorithms)</p>
                            <p className='dark:text-text font-light text-xl'>Space X Avenue is validating the blockchain networks of many of the majorly distributed cryptocurrencies such as Ethereum, Litecoin, Zcash, Dash and others. The most popular and widespread cryptocurrency, Bitcoin, is being mined in facilities with a different hardware setup (such as ASIC miners).</p>
                        </div>
                    </div>                    
                </div>


            </Carousel>
        </div>
    </div>
)

const faq = [
    {
        title : "How does copy trading work?",
        content : `Here is how the copier works: You, as an investor, simply select an expert or experts that you want to copy trades from. Once you are signed up, this is the only action needed on your part.


        Once you’ve taken care of the above, you are all set. There are no codes that you need to run or signals for you to manually input. Our software will handle the trade copying automatically on your behalf. We monitor your experts trading activity and as soon as there is a trade, we calculate all the necessary parameters and execute the trade.
        
        
        The only thing you have to make sure of is that you have enough available base currency that your expert trades with, in your trading account. How much is enough? First, you must meet the exchanges minimum order amount (let’s say about $10 per trade to be safe). That means that if your expert executes a 5% order, you must have at least $300 in your account total value (at 100% expert allocation as an example). This also means that you need to have at least 10% or higher in available base currency to avoid missed trades.
        
        
        When the expert exits a position, you too will exit it. Automatically. You can also change allocation at any time.`
    },
    {
        title : "Who are the trading experts?",
        content : `We carefully select expert applicants. We get to know them as a trader and examine their trading performance over a period of time. We also tend to look for expert who already have a following to further confirm their competence (social proof). You can also read about every expert on their individual performance pages.

        `
    },
    {
        title : "What is the recommended amount to start with?",
        content : `We suggest to have around $3000-$5000 in your account in BTC value due to exchanges minimum order requirements and so that you can at least cover the subscription cost every month.

        `
    },
    {
        title : "How does Bitcoin mining work?",
        content : `It’s quick and very easy! As soon as we receive your payment your contract will be added to your profile, and you can immediately start mining. Depending on the blockchain algorithm you select and the associated mining service agreement you enter into, you can either mine native cryptocurrencies directly or allocate your hashpower to other cryptocurrencies (marked with AUTO), and even choose a specific allocation for them. For example: 60% LTC, 20% BTC and 20% DOGE. The first mining output is released after 48 hours, and then a daily mining output will follow.
        `
    },
    {
        title : "How does Bitcoin mining work?",
        content : `Besides the fact that we ourselves mine with the very same hardware that we offer to our clients, our capital is limited. We believe that Bitcoin and Altcoin mining is one of the best ways to receive Cryptocurrencies, however, we do not want to “put all our eggs in one basket”.
        `
    },
    {
        title : "What are the expected returns?",
        content : `Bitcoin and altcoin mining depends highly on the prices and network difficulties of the Cryptocurrencies that you are mining, which cannot be predicted. It would not “look right” if we would provide data on the mining performance and sell contracts at the same time. Therefore, we recommend all our clients to do their own research and do their own performance calculation.

        For example, you might want to have a look at coinwarz.com. Remember that some of our products have a daily maintenance fee which must be deducted from the daily mining rewards.
        
        Please note that the website is an independent source of reference and not related to Space X Avenue in any manner.
        
        `
    },
]

const FAQ = () => (
    <div className='px-5 py-16 sm:p-16 bg-gray-50 text-black dark:bg-black dark:text-white space-y-10'>
        <p className='text-3xl text-center'>FAQS</p>

        <div className='grid sm:grid-cols-2 gap-6'>
            {
                faq.map(({title, content}) => (
                    <Fade bottom>
                    <Disclosure>
                        <Disclosure.Button className="p-5 bg-white shadow dark:bg-bg w-full dark:text-white text-left text-xl flex items-center gap-4">
                            <ChevronDownIcon className='h-6'/>
                            <span>{title}</span>
                        </Disclosure.Button>
                        <Disclosure.Panel className="dark:text-text p-5">
                            {content}
                        </Disclosure.Panel>
                    </Disclosure>
                </Fade>
                ))
            }
        </div>
    </div>
)

export default Section