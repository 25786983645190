import React from 'react'
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from '@heroicons/react/24/solid'

const Footer = () => {
  return (
    <div className='bg-sec p-20 grid gap-10 sm:grid-cols-4 text-center text-white'>
        <div className='flex justify-center items-center flex-col'>
            <img src="logo.png" alt="" className='h-16 w-16' />
            <p>Space X Avenue</p>
        <div>

        </div>
        </div>
        <div className='space-y-3'>
            <p className='font-semibold'>Our Contact</p>

            <ul className='space-y-2'>
                <li className='flex gap-4 items-center justify-center hover:text-gray-300'><EnvelopeIcon className='h-4 hidden lowercase'/> <a href="mailto:support@spacexavenue.com">support@spacexavenue.com</a></li>
            </ul>
        </div>

        <div className='space-y-3'>
            <p className='font-semibold'>Learn More</p>

            <ul className='space-y-2'>
                <li>About Us</li>
                <li>Contact Us</li>
                <li>My Account</li>
                <li>Create Account</li>
            </ul>
        </div>
        <div className='space-y-3'>
            <p className='font-semibold'>Top Instrument</p>

            <ul className='space-y-2'>
                <li className='flex gap-4 items-center justify-center hover:text-gray-300'>Bitcoin</li>
                <li className='flex gap-4 items-center justify-center hover:text-gray-300'>Shiba</li>
                <li className='flex gap-4 items-center justify-center hover:text-gray-300'>Ethereum</li>
                <li className='flex gap-4 items-center justify-center hover:text-gray-300'>Tesla</li>
            </ul>
        </div>
    </div>
  )
}

export default Footer