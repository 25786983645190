import React from 'react'
import Navbar from './navbar'
import Header from './Header'
import Footer from './Footer'

const Contact = () => {
  return (
    <div>
        <Navbar/>
        <Header
        title={"Contact Us"}
        bg={"/contact.jpg"}/>

        <form className='bg-bg p-16 space-y-10'>
            <div className='grid md:grid-cols-2 gap-4'>
                <input type="text" placeholder='Your Name*' className='border border-gray-200 text-gray-200 rounded-lg w-full bg-transparent p-3' />
                <input type="text" placeholder='Email Address*' className='border border-gray-200 text-gray-200 rounded-lg w-full bg-transparent p-3' />
            </div>
            <div className='grid md:grid-cols-2 gap-4'>
                <input type="text" placeholder='Phone Number*' className='border border-gray-200 text-gray-200 rounded-lg w-full bg-transparent p-3' />
                <input type="text" placeholder='Subject' className='border border-gray-200 text-gray-200 rounded-lg w-full bg-transparent p-3' />
            </div>
            <div>
                <textarea placeholder='Your Message Here...' className='border w-full border-gray-200 text-gray-200 rounded-lg w-full bg-transparent p-3'/>
            </div>

            <button className='bg-primary text-white p-2'>
                Send Message
            </button>

            <p className='text-white'><b>Note:</b> We promise that we don't do spam & your mail address is confidential.</p>
        </form>

        <Footer/>
    </div>
  )
}

export default Contact