import { useEffect, useState } from 'react';
import './App.css';
import HeroPage from './components/heroPage';
import classNames from 'classnames';
import Cookies from 'universal-cookie';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Navbar from './components/navbar';
import Section from './components/Section';
import Footer from './components/Footer';

function Smart() {
  const [ darkmode, setDarkmode ] = useState(true);
  const cookies = new Cookies(null, { path: '/' });
  const [ language, setLanguage ] = useState("");

  useEffect( () => {
    let lang = cookies.get('googtrans');
    
    setLanguage(lang ? lang : "/en/en");
  }, [language]);

  const handleChange = (lang) => {
    cookies.set('googtrans', lang);
    setLanguage(lang);

    window.location.reload();
  }


  return (
    <div className={classNames({
      'dark' : darkmode
      })}>
      <Navbar setLanguage={handleChange} language={language} setDarkmode={setDarkmode} darkmode={darkmode}/>
      <HeroPage/>
      <Section/>
      <Footer/>
    </div>
  );
}

export default Smart;
