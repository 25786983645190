import React from 'react'
import Navbar from '../navbar'
import Header from '../Header'
import Footer from '../Footer'

const CookiePolicy = () => {
  return (
    <div>
        <Navbar/>
        <Header
        title={"Cookie Policy"}
        bg={"/cookie.jpg"}/>

        <div className='bg-black py-8 px-4 sm:p-20 text-white'>
            <div className='bg-bg p-10'>
                <h1 className='text-5xl font-bold text-center'>Cookie Policy</h1>
                <br /><br />
                <div className="container"><p>Space X Avenue uses cookies to: Optimize your trading experience, including remembering your preferences, location, preferred language, browser and other details;</p><br/>

                <p>Authenticate your identity for security purposes;</p><br/>

                <p>Maintain our website and certain functions available on it;</p><br/>

                <p>Analyze and track the use of our services;</p><br/>
                <p>Adjust our platform according to your trading habits and our regulatory requirements.</p><br/>
                <br/>
                <br/>
                <div>WHAT ARE COOKIES</div><br/>
                <div>Cookies are tiny pieces of data that are sent to your computer by a website and stored on your computer. Cookies are non-executable and cannot be used to install malware. They allow websites to recognize visitors when they return and include basic information about them that is checked and updated every time you visit the website. For more information see: http://www.allaboutcookies.org/.</div><br/>
                <br/>
                <br/>
                <div>MANAGING COOKIES</div><br/>
                <div>At Space X Avenue, we respect your right to privacy and are therefore happy to provide you with tools to manage the cookies you receive from our web services. Some cookies are essential to the performance of our platform (you cannot opt out from these cookies if you wish to use our platform). Below you will find a list of some of the third party cookies used by Space X Avenue.  For those users who would prefer not to receive non-essential cookies, we have provided an “opt out” option for you to select.</div><br/>
                <div>Your browser may also allow you to block the storage of cookies on your computer; please refer to your browser’s “Help” menu or website for more information. If you use our web services without blocking cookies you are actually consenting to the cookies.</div></div>
            </div>
        </div>
        <Footer/>

    </div>
  )
}

export default CookiePolicy