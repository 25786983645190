import React from 'react'

const HeroPage = () => {
  return (
    <div className='h-screen relative'>
        <video autoPlay playsInline muted loop className='absolute w-full h-full -z-10 object-cover'>
            <source src='/video.mp4' type='video/mp4' />
        </video>
        <div style={{ background : "rgba(1, 1, 33, 0.5)"}} className='text-center text-white h-full z-20 w-full flex justify-center items-center'>
        <div className='space-y-2 sm:w-2/3 md:w-1/2 mx-auto'>
            <h1 class="text-5xl font-bold">Trade Stocks, Forex, Crypto and Indices</h1>
            <p class="">Space X Avenue is a leading platform that allows people to grow and diversify their portfolio of assets across different markets and technologies..</p>
            <div className='flex gap-2 justify-center'>
                <a class="px-4 py-2 bg-primary rounded-lg" href="https://spacexavenue.com/account/login">START TRADING</a>
                <a class="px-4 py-2 border rounded-lg" href="https://spacexavenue.com/account/login">CREATE ACCOUNT</a></div>                    
            </div>
        </div>
    </div>
  )
}

export default HeroPage