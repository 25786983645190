import React from 'react'

const Header = ({title, bg}) => {
  return (
    <div className='flex relative justify-center items-center py-28 text-white w-full' style={{ background : `url(${bg})`, backgroundSize: "cover", backgroundPosition :"center", backgroundRepeat : "no-repeat"}}>
        <div className='absolute h-full w-full z-10 overlay1'></div>
        <p className='text-5xl capitalize font-bold z-20'>{title}</p>
    </div>
  )
}

export default Header