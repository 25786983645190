import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, ComputerDesktopIcon, HomeIcon, SunIcon, UserIcon, UserPlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Dialog } from '@headlessui/react'
import { ChartBarIcon, ChartBarSquareIcon, DocumentDuplicateIcon, EnvelopeIcon, FlagIcon, FolderIcon, LockOpenIcon, MoonIcon, UserGroupIcon, WindowIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const navigation = [
  { name: 'Contact', href: '/contact-us', current: true },
  { name: 'Register', href: 'https://spacexavenue.com/account/login', current: false },
  { name: 'Login', href: 'https://spacexavenue.com/account/login', current: false },
]

const languageData = [
  {
    language: "English",
    icon: "fi fi-us",
    googleTranslateCode: "/en/en",
  },
  {
    language: "Spanish",
    icon: "fi fi-es",
    googleTranslateCode: "/en/es",
  },
  {
    language: "French",
    icon: "fi fi-fr",
    googleTranslateCode: "/en/fr",
  },
  {
    language: "German",
    icon: "fi fi-de",
    googleTranslateCode: "/en/de",
  },
  {
    language: "Chinese",
    icon: "fi fi-cn",
    googleTranslateCode: "/en/zh-CN",
  },
  {
    language: "Japanese",
    icon: "fi fi-jp",
    googleTranslateCode: "/en/ja",
  },
  {
    language: "Arabic",
    icon: "fi fi-sa",
    googleTranslateCode: "/en/ar",
  },
  {
    language: "Russian",
    icon: "fi fi-ru",
    googleTranslateCode: "/en/ru",
  },
  {
    language: "Portuguese",
    icon: "fi fi-br",
    googleTranslateCode: "/en/pt-BR",
  },
  {
    language: "Italian",
    icon: "fi fi-it",
    googleTranslateCode: "/en/it",
  },
];


const menu = [
    {
      icon: HomeIcon,
      title: "Home",
      href: "/"
    },
    {
      icon: UserIcon,
      title: "Sign In",
      href: "https://spacexavenue.com/account/login"
    },
    {
      icon: UserPlusIcon,
      title: "Sign Up",
      href: "https://spacexavenue.com/account/login"
    },
    {
      icon: EnvelopeIcon,
      title: "Contact Us",
      href: "/contact-us"
    },
    {
      icon: UserGroupIcon,
      title: "About Us",
      href: "/about-us"
    },
  ];
  
  
const Navbar = ({darkmode, setDarkmode, setLanguage, language}) => {
    const [scrolling, setScrolling] = useState(false);

  // Add a scroll event listener to track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(darkmode)
    const [open, setOpen] = useState(false)
    return(
        <>
            <div className='fixed z-20 w-full'>
                <div className={`relative flex h-16 items-center justify-between ${scrolling && "dark:bg-black bg-sec"}`}>
                        <div className="inset-y-0 left-0 flex items-center p-2" onClick={() => setOpen(!open)}>
                            <Bars3Icon className="block h-6 w-6 text-white" aria-hidden="true" />
                        </div>
                        <div className="flex flex-1 text-white font-semibold items-center justify-between">
                            <div className="flex items-center">
                            <img
                                className="h-8 w-auto"
                                src="/logo.png"
                                alt="Your Company"
                            />
                            </div>
                            <div className="hidden sm:ml-6 sm:block">
                                <div className="flex space-x-4">                   
                                    <button>
                                        <Language setLanguage={setLanguage} language={language}/>
                                    </button>    
                                    <button onClick={() => setDarkmode(!darkmode)}>
                                        {
                                            darkmode ? <SunIcon className='h-6'/> : <MoonIcon className='h-6'/>
                                        }
                                    </button>                 
                                    {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className={'text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium'}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </a>
                                    ))}
                                </div>  
                            </div>
                            <div className='space-x-4 sm:hidden flex items-center gap-4 px-4'>
                                <Language setLanguage={setLanguage} language={language}/> 
                                <button onClick={() => setDarkmode(!darkmode)}>
                                    {
                                        darkmode ? <SunIcon className='h-6'/> : <MoonIcon className='h-6'/>
                                    }
                                </button>  
                            </div>
                        </div>
                </div>
            </div>
            <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={setOpen}>
                <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="pointer-events-auto relative w-screen max-w-xs">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="mx-auto px-5">
                            </div>



                        </Transition.Child>
                        <div className="flex h-full flex-col overflow-y-scroll bg-bg text-white shadow-xl">
                            <div className="">
                            <Dialog.Title className="text-base font- leading-6">
                                
                                <div className='bg-black'>
                                    <img src="logo (1).png" alt="lgo" className='h-28 w-28' />
                                </div>

                                <div className='p-5'>
                                    {
                                        menu.map(({icon:Icon, title, href}) => (
                                            <a href={href}>
                                            <div className='flex gap-4 font-600 px-4 py-4'>
                                                <Icon className='h-5'/>
                                                <span className='text-sm'>{title}</span>
                                            </div>                                            
                                            </a>

                                        ))
                                    }
                                </div>

                            </Dialog.Title>
                            </div>
                            <div className="relative mt-6 flex-1 px-4 sm:px-6">{/* Your content */}</div>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </div>
            </Dialog>
            </Transition.Root>        
        </>

  )
}

export default Navbar;


  

const Language = ({setLanguage,language}) => {
  let icon = languageData.find((item) => item.googleTranslateCode === language)?.icon;
  return (
    <div className="text-right">
    <Menu as="div" className="relative inline-block">
      <div>
        <Menu.Button className="relative">
          <div className='flex gap-2 items-center relative'>
          <i className={icon}></i>
          </div>

        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in usduration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute text-black right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {
                languageData.map((item) => (
                    <Menu.Item>
                        <div onClick={() => {setLanguage(item.googleTranslateCode)} } className='flex gap-4 p-4 items-center'>
                         <i className={item.icon}></i>
                         <span>{item.language}</span>
                        </div>
                    </Menu.Item>
                ))
            }
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
  )
}

function IconOne() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <path
        d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z"
        stroke="#FB923C"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
        stroke="#FDBA74"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
        stroke="#FDBA74"
        strokeWidth="2"
      />
    </svg>
  )
}

function IconTwo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <path
        d="M28.0413 20L23.9998 13L19.9585 20M32.0828 27.0001L36.1242 34H28.0415M19.9585 34H11.8755L15.9171 27"
        stroke="#FB923C"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.804 30H29.1963L24.0001 21L18.804 30Z"
        stroke="#FDBA74"
        strokeWidth="2"
      />
    </svg>
  )
}

function IconThree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <rect x="13" y="32" width="2" height="4" fill="#FDBA74" />
      <rect x="17" y="28" width="2" height="8" fill="#FDBA74" />
      <rect x="21" y="24" width="2" height="12" fill="#FDBA74" />
      <rect x="25" y="20" width="2" height="16" fill="#FDBA74" />
      <rect x="29" y="16" width="2" height="20" fill="#FB923C" />
      <rect x="33" y="12" width="2" height="24" fill="#FB923C" />
    </svg>
  )
}
